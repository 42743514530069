import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { toJpeg } from 'html-to-image';
import { BrahmiText, CompanyIcon, Container, ContentArea, FooterArea, IconArea, MainContainer, SaveButton, SaveButtonArea, Title } from './assets/css/styled-component';

import brandIcon from './../../assets/images/company-icon.png';
import mesumeIcon from './../../assets/images/mesume-icon.png';
import DataContext from '../../Context/data-context';
import Loading from '../Alert';

const SaveImage = () => {

    const { data } = useContext(DataContext);
    const [ isRedirect, setRedirect ] = useState(false);
    const [hindiText, setHindiText] = useState('');
    const [brahmiText, setBrahmiText] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const ref = useRef();

    useEffect(() => {
        console.log()
        if(data.hindiText === null || data.hindiText === undefined)
            setRedirect(true);
        else {
            setHindiText(data.hindiText)
            setBrahmiText(data.brahmiText)
            setTimeout(toDownload, 2000);
        }
        
    }, [])

    const toDownload = useCallback(() => {
        if (ref.current === null)
            return;

        toJpeg(ref.current, { cacheBust: true, })
        .then((dataUrl) => {
            const link = document.createElement('a')
            link.download = `Brahmi Text.jpeg`
            link.href = dataUrl
            link.click();
            setTimeout(loadRestartButton, 1000);
        })
        .catch((err) => {
            console.log(err)
        })
    }, [ref]);

    const loadRestartButton = () => {
        setIsLoading(false);
    }

    return  <Fragment>
        { isLoading && <Loading />}
        <MainContainer filter={isLoading}>  
        {isRedirect && <Navigate replace to="/" />}
        <Container ref={ref} >
            <ContentArea>
                <Title>~ {hindiText} ~</Title>
                <BrahmiText id='brahmi-text'>{brahmiText}</BrahmiText>
                <FooterArea>
                    <CompanyIcon src = {brandIcon} />
                    {   
                        !isLoading && <SaveButton onClick = {() => setRedirect(true)}>Restart</SaveButton>
                    }
                    <CompanyIcon src = {mesumeIcon} />
                </FooterArea>
            </ContentArea>
        </Container>
    </MainContainer>
    </Fragment>
        
}

export default SaveImage;