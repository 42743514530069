import styled, { keyframes } from "styled-components";

export const MainContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: #00000094;
    z-index: 3;
`;

const rotateAnim = keyframes`
    0% { rotate: 0deg; }
    100% { rotate: 360deg; }
`;

const opacityAnim = keyframes`
    0% { font-size: 0rem; }
    50% { font-size: 4rem; }
    90% { font-size: 0rem; }`
;

export const LoadingArea = styled.img`
    height: 250px;
    width: 250px;
    animation: ${rotateAnim} 2s ease-in-out infinite;
    margin: auto;
`;

export const Character = styled.span`
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    animation: ${opacityAnim} 2s linear infinite;
`;

