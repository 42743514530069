import React, { useEffect, useState } from 'react'
import { Character, LoadingArea, MainContainer } from './assets/css/styled-component';

import loadingIcon from './assets/images/loading-icon.png'

let char = ['𑀙', '𑀖', '𑀨', '𑀣', '𑀤', '𑀖',];

const Loading = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        setInterval(() => {
            setIndex((idx) => (idx + 1) % 5)
        }, 1900);
    })
    return (
        <MainContainer>
            <LoadingArea src={loadingIcon} />
            <Character>{char[index]}</Character>
            
        </MainContainer>
    )
}

export default Loading;