import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { BrahmiText, ButtonArea, CompanyIcon, Container, ContentArea, ConvertButton, FooterArea, IconArea, InputArea,  InputTextArea, MainContainer, MobileTitle, SaveButton, SaveButtonArea, Title } from './assets/css/styled-component';
import { convertToBrahmi } from './../../helper/script/index';

import brandIcon from './../../assets/images/company-icon.png';
import DataContext from '../../Context/data-context';
import Loading from '../Alert';

const Home = () => {
    const { setData } = useContext(DataContext);
    const [ isRedirect, setRedirect ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isMobile, setIsMobile ] = useState(false);
    
    useEffect(() => {
        if(window.isMobile) {
            setIsMobile(true);
        } else {
            window.CreateHindiTextBox('text-box', 'input-area');
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    }, []);

    const convertText = () => {
         convertToBrahmi(document.getElementById('text-box').value);
    }

    const saveClick = () => {
        let hindiText = document.getElementById('text-box').value;
        if(hindiText === "")
            return;
        let brahmiText = document.getElementById('brahmi-text').innerHTML;
        setData({hindiText, brahmiText});
        setRedirect(true);
    }

    return  <Fragment>
        { isLoading && <Loading />}
        <MainContainer filter={`${isLoading}`}>
            {isRedirect && <Navigate replace to="/save-image" />}
            <Container>
                {
                    isMobile
                    ? <MobileComponent />
                    : <DesktopComponent saveClick={saveClick} convertText={convertText}/>
                }
            </Container>
        </MainContainer>
    </Fragment>
}

const DesktopComponent = ({saveClick, convertText}) => {
    return <ContentArea>
            <Title>~ Convert Your Name In Brahmi Script ~</Title>
            <InputArea>
                <InputTextArea id='input-area'>
                    {/* <InputText type={'text'}  /> */}
                </InputTextArea>
                <ConvertButton onClick={convertText}>Convert</ConvertButton>
            </InputArea>
            <BrahmiText id='brahmi-text'></BrahmiText>
            <FooterArea>
                    <CompanyIcon src = {brandIcon} />
                    <SaveButton onClick={saveClick}>Save Image</SaveButton>
            </FooterArea>
        </ContentArea>
}

const MobileComponent = () => {
    return <ContentArea>
            <MobileTitle>For best viewing experience see it on Desktop / PC</MobileTitle>
            <FooterArea>
                <CompanyIcon src = {brandIcon} />
            </FooterArea>
        </ContentArea>
}

export default Home;