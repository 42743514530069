export const convertToBrahmi = (text) => {
    let b = '';
    for (let i = 0; i < text.length; i++) {
        var n = text.codePointAt(i);
        switch(n){
            case 0x902:
                b += "&#x11001";
                break;
            case 0x905: //A
                b += "&#x11005";
                break;
            case 0x906: //AA
                b += "&#x11006";
                break;
            case 0x907: //I
                b += "&#x11007";
                break;
            case 0x908: //II
                b += "&#x11008";
                break;
            case 0x909: //U
                b += "&#x11009";
                break;
            case 0x90A: // UU
                b += "&#x1100A";
                break;
            case 0x90B: //Vocalic R
                b += "&#x1100B";
                break;
            case 0x90C: //Vocalic L
                b += "&#x1100D";
                break;
            case 0x90F: // E
                b += "&#x1100F";
                break;
            case 0x910: // AI
                b += "&#x11010";
                break;
            case 0x913: // O
                b += "&#x11011";
                break;
            case 0x914: // AU
                b += "&#x11012";
                break;
            case 0x915: // KA
                b += "&#x11013";
                break;
            case 0x916: // KHA
                b += "&#x11014";
                break;
            case 0x917: // GA
                b += "&#x11015";
                break;
            case 0x918: // GHA
                b += "&#x11016";
                break;
            case 0x919: // NGA
                b += "&#x11017";
                break;
            case 0x91A: // CA
                b += "&#x11018";
                break;
            case 0x91B: // CHA
                b += "&#x11019";
                break;
            case 0x91C: // JA
                b += "&#x1101A";
                break;
            case 0x91D: // JHA
                b += "&#x1101B";
                break;
            case 0x91E: // NYA
                b += "&#x1101C";
                break;
            case 0x91F: // TTA
                b += "&#x1101D";
                break;
            case 0x920: // TTHA
                b += "&#x1101E";
                break;
            case 0x921: // DDA
                b += "&#x1101F";
                break;
            case 0x922: // DDHA
                b += "&#x11020";
                break;
            case 0x923: // NNA
                b += "&#x11021";
                break;
            case 0x924: // TA
                b += "&#x11022";
                break;
            case 0x925: // THA
                b += "&#x11023";
                break;
            case 0x926: // DA
                b += "&#x11024";
                break;
            case 0x927: // DHA
                b += "&#x11025";
                break;
            case 0x928: // NA
                b += "&#x11026";
                break;
            case 0x92A: //PA
                b += "&#x11027";
                break;
            case 0x92B: //PHA
                b += "&#x11028";
                break;
            case 0x92C: // BA
                b += "&#x11029";
                break;
            case 0x92D: // BHA
                b += "&#x1102A";
                break;
            case 0x92E: // MA
                b += "&#x1102B";
                break;
            case 0x92F: // YA
                b += "&#x1102C";
                break;
            case 0x930: // RA
                b += "&#x1102D";
                break;
            case 0x932: // LA
                b += "&#x1102E";
                break;
            case 0x933: // LLA
                b += "&#x11034";
                break;
            case 0x935: // VA
                b += "&#x1102F";
                break;
            case 0x936: // SHA
                b += "&#x11030";
                break;
            case 0x937: // SSA
                b += "&#x11031";
                break;
            case 0x938: // SA
                b += "&#x11032";
                break;
            case 0x939: // HA
                b += "&#x11033";
                break;
            case 0x93E: // AA
                b += "&#x11038";
                break;
            case 0x93F: // I
                b += "&#x1103A";
                break;
            case 0x940: // II
                b += "&#x1103B";
                break;
            case 0x941: // U
                b += "&#x1103C";
                break;
            case 0x942: //UU
                b += "&#x1103D";
                break;
            case 0x943: //R
                b += "&#x1103E";
                break;
            case 0x944: //RR
                b += "&#x1103F";
                break;
            case 0x962: //L
                b += "&#x11040";
                break;
            case 0x963: //LL
                b += "&#x11041";
                break;
            case 0x947: //E
                b += "&#x11042";
                break;
            case 0x948: //AI
                b += "&#x11043";
                break;
            case 0x94B: //O
                b += "&#x11044";
                break;
            case 0x94C: //AU
                b += "&#x11045";
                break;
            case 0x94D: //virama
                b += "&#x11046";
                break;
            case 0x964: // danda
                b += "&#x11047";
                break;
            case 0x965: // double danda
                b += "&#x11048";
                break;
            case 0x966: //0
                b += "&#x11066";
                break;
            case 0x967: //1
                b += "&#x11067";
                break;
            case 0x968: //2
                b += "&#x11068";
                break;
            case 0x969: //3
                b += "&#x11069";
                break;
            case 0x96A: //4
                b += "&#x1106A";
                break;
            case 0x96B: //5
                b += "&#x1106B";
                break;
            case 0x96C: //6
                b += "&#x1106C";
                break;
            case 0x96D: //7 
                b += "&#x1106D";
                break;
            case 0x96E: //8
                b += "&#x1106E";
                break;
            case 0x96F: //9
                b += "&#x1106F";
                break;
            default:
                b += "&#"+n;
                break;
        }
    }
    document.getElementById('brahmi-text').innerHTML = b;
}