import styled from "styled-components";

import PalmBackgroun from './../../../../assets/images/palm-background.png';
import ButtonBackground from './../../../../assets/images/button-background.png';

let TextColor = '#32100E';
let ComponentWidth = '60%';

export const MainContainer = styled.div`
    height: 100%;
    width: 100%;
    filter: ${props => props.filter === 'true' ? 'blur(30px);' : 'none;'}
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    height: 100%;
    width: auto;
`;

export const ContentArea = styled.div`
    height: 100%;
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    background-image: url(${PalmBackgroun});
    background-repeat: no-repeat;
    background-size: 90% 90%;
    margin: auto auto;
    background-position: center;

    @media (max-width: 920px) {
        background-size: auto 90%;
    }
`;

export const Title = styled.p`
    font-size: 3.5rem;
    text-align: center;
    margin: 3rem 0;
    color: ${TextColor};    
    margin-bottom: 5rem;
    text-shadow: 1px 2px 4px #814027;

    @media (max-width: 720px) {
        font-size: 1.2rem;
        margin: 3rem 5px;
    }
`;


export const MobileTitle = styled.p`
    font-size: 2rem;
    text-align: center;
    margin: 3rem 0;
    width: 98%;
    color: ${TextColor};
`;

export const InputArea = styled.div`
    display: flex;
    align-item: center;
    justify-content: space-between;
    margin: 0 auto;
    width: ${ComponentWidth};

    @media (max-width: 720px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const InputTextArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export const InputText = styled.input`
    outline: none;
    font-size: 2.3rem;
    padding: 0.5rem 1rem;
    margin: 0 2rem;
    color: ${TextColor};
    border: 2px solid #754822;
    
`;

export const ButtonArea = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const ConvertButton = styled.button`
    background: url(${ButtonBackground});
    background-size: 100% 100%;
    border: none;
    font-size: 1.5rem;
    height: auto;
    padding: 0.5rem 2rem 1rem 2rem;
    color: ${TextColor};

    @media (max-width: 720px) {
        width: min-content;
        margin-top: 1rem;
    }

    
`;

export const BrahmiText = styled.p`
    font-size: 4rem;
    min-height: 4rem;
    text-align: center;
    margin: 5rem 0;
    color: ${TextColor};

    @media (max-width: 720px) {
        font-size: 2rem;
        min-height: 3.5rem;
        margin: 3rem 10px;
    }
`;

export const FooterArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    width: ${ComponentWidth};
    margin: 0 auto;
    padding-bottom: 2rem;

    @media (max-width: 720px) {
        flex-direction: column-reverse;
        width: 100%;
    }
`;

export const IconArea = styled.div`
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: center;

    @media (max-width: 720px) {
        justify-content: center;
        width: 100%;
        margin-top: 2rem;
    }
`;

export const CompanyIcon = styled.img`
    width: 200px;
    @media (max-width: 720px) {
        margin-top: 3rem;
    }
`;

export const BrandIcon = styled.img`
    margin: 0px 50px;
    width: 100px;
    @media (max-width: 720px) {
        margin: 0px 30px;
    }
`;

export const SaveButtonArea = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    JUSTIFY-CONTENT: flex-start;
`;

export const SaveButton = styled.button`
    background: url(${ButtonBackground});
    background-size: 100% 100%;
    border: none;
    font-size: 1.5rem;
    height: auto;
    padding: 0.5rem 2rem 1rem 2rem;
    color: ${TextColor};
`;