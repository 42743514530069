import React, { useState  } from 'react'
import {BrowserRouter as Router, Route, Routes  } from 'react-router-dom';

import Home from './Components/Home';
import SaveImage from './Components/ImageCard';
import DataContext from './Context/data-context';

const Main = () => {
    const [data, setData] = useState({})
    return (
        <DataContext.Provider value={{data, setData}}>
           <Router>
            <Routes>
              <Route exact path="/save-image" element={<SaveImage />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </Router>
          </DataContext.Provider>
      );
}

export default Main;