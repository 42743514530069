import styled from "styled-components";

import PalmBackgroun from './../../../../assets/images/palm-background.png';
import ButtonBackground from './../../../../assets/images/button-background.png';

let TextColor = '#32100E';

export const MainContainer = styled.div`
    height: 100%;
    width: 100%;
    filter: ${props => props.filter ? 'blur(30px);' : 'none;'}
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    height: 100%;
    width: auto;
`;

export const ContentArea = styled.div`
    height: 100%;
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    background-image: url(${PalmBackgroun});
    background-repeat: no-repeat;
    background-size: 90% 90%;
    margin: auto auto;
    background-position: center;

    @media (max-width: 920px) {
        background-size: auto 90%;
    }
`;

export const Title = styled.p`
    font-size: 3rem;
    text-align: center;
    color: ${TextColor};

    @media (max-width: 720px) {
        font-size: 1.2rem;
    }
`;

export const BrahmiText = styled.p`
    font-size: 4.5rem;
    text-align: center;
    margin: 5rem 0;
    color: ${TextColor};
    letter-spacing: -6px;

    @media (max-width: 720px) {
        font-size: 2rem;
        margin: 4rem 0;
    }
`;

export const FooterArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;

    @media (max-width: 720px) {
        flex-direction: column-reverse;
    }
`;

export const IconArea = styled.div`
    display: flex;
    width: 70%;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 720px) {
        justify-content: center;
        width: 100%;
        margin-top: 2rem;
    }
`;

export const CompanyIcon = styled.img`
    width: 200px;
    @media (max-width: 720px) {
        margin-top: 2rem;
    }
`;

export const BrandIcon = styled.img`
    margin: 0px 50px;
    width: 100px;

    @media (max-width: 720px) {
        width: 80px;
    }
`;


export const SaveButtonArea = styled.div`
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SaveButton = styled.button`
    background: url(${ButtonBackground});
    background-size: 100% 100%;
    border: none;
    font-size: 2rem;
    height: auto;
    padding: 0.7rem 2rem 1rem 2rem;
    color: ${TextColor};

    @media (max-width: 720px) {
        margin: 2rem auto;
    }

`;